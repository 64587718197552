import * as Types from '@rewards-web/shared/graphql-types';

import { GoalCardFragmentFragment } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import { gql } from '@apollo/client';
import { GoalCardFragmentFragmentDoc } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GoalActivationPageDataQueryVariables = Types.Exact<{
  goalId: Types.Scalars['ID'];
}>;


export type GoalActivationPageDataQuery = (
  { __typename?: 'Query' }
  & { getGoalById?: Types.Maybe<(
    { __typename?: 'Goal' }
    & GoalCardFragmentFragment
  )> }
);


export const GoalActivationPageDataDocument = gql`
    query GoalActivationPageData($goalId: ID!) {
  getGoalById(goalId: $goalId) {
    ...GoalCardFragment
  }
}
    ${GoalCardFragmentFragmentDoc}`;

/**
 * __useGoalActivationPageDataQuery__
 *
 * To run a query within a React component, call `useGoalActivationPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalActivationPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalActivationPageDataQuery({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGoalActivationPageDataQuery(baseOptions: Apollo.QueryHookOptions<GoalActivationPageDataQuery, GoalActivationPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalActivationPageDataQuery, GoalActivationPageDataQueryVariables>(GoalActivationPageDataDocument, options);
      }
export function useGoalActivationPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalActivationPageDataQuery, GoalActivationPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalActivationPageDataQuery, GoalActivationPageDataQueryVariables>(GoalActivationPageDataDocument, options);
        }
export type GoalActivationPageDataQueryHookResult = ReturnType<typeof useGoalActivationPageDataQuery>;
export type GoalActivationPageDataLazyQueryHookResult = ReturnType<typeof useGoalActivationPageDataLazyQuery>;
export type GoalActivationPageDataQueryResult = Apollo.QueryResult<GoalActivationPageDataQuery, GoalActivationPageDataQueryVariables>;