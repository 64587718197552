import {
  BillableHoursGoalTracker,
  GoalStatus,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import briefcaseImageUrl from "../../graphics/briefcase.png";
import { BaseGoalCard } from "../base-goal-card";
import { useCommonBaseGoalCardProps } from "../lib";

export function BillableHoursGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage, formatDate } = useFormatters();
  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/billable-hours",
  });

  const buttonLabel = (() => {
    if (goal.activatedAt) {
      return formatMessage({
        defaultMessage: "Requested",
        description: "Goal card > billable hours > button label activated",
      });
    } else {
      return formatMessage({
        defaultMessage: "I'm interested",
        description: "Goal card > billable hours > button label not activated",
      });
    }
  })();

  const detailText = (() => {
    switch (goal.status) {
      case GoalStatus.Achieved:
        return formatDate(goal.achievedAt, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      case GoalStatus.Available:
      case GoalStatus.Locked:
        return goal.activatedAt
          ? formatMessage({
              defaultMessage:
                "A coordinator will contact you to increase your hours accordingly. Shift data may take several days to be reflected.",
              description: "Goal card > billable hours > activated detail text",
            })
          : formatMessage(
              {
                defaultMessage:
                  "Increase your hours this week and earn points when you reach your goal by {goal_expires_at, date, long}.",
                description: "Goal card > billable hours > detail text",
              },
              {
                goal_expires_at: new Date(goal.expiresAt),
              }
            );
    }
  })();

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      progressProps={
        goal.activatedAt
          ? {
              max: (goal.goalTracker as BillableHoursGoalTracker)
                .targetBillableHours,
              value: (goal.goalTracker as BillableHoursGoalTracker)
                .trackedBillableHours,
              getUnitsLabel: ({ max, value }) =>
                formatMessage(
                  {
                    defaultMessage: "{value, number}/{max, number} hrs",
                    description: "Goal card > billable hours > progress units",
                  },
                  { max, value }
                ),
            }
          : undefined
      }
      icon={<img src={briefcaseImageUrl} alt="calendar" />}
      detailText={detailText}
      primaryButtonProps={
        goal.status === GoalStatus.Available
          ? {
              label: buttonLabel,
              disabled: Boolean(goal.activatedAt),
              linkTo: `/goal-activation/${goal.id}`,
            }
          : undefined
      }
    />
  );
}
