/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { usePrevious } from "@rewards-web/shared/hooks/use-previous";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { COMPUTER_NAVIGATION_BAR_HEIGHT } from "../../pages/authenticated/navigation-container";
import { BACK_NAVIGATION_BAR_HEIGHT, SubPageHeader } from "../sub-page-header";
import { FullscreenButton } from "./fullscreen-button";
import { closeFullscreenIfOpen } from "./lib";
import { PlayButton } from "./play-button";
import { ProgressBar } from "./progress-bar";
import { useRecordVideoWatchedMutation } from "./record-video-watched.generated";
import {
  useVideoTrackingHandlers,
  VideoTrackingHandlers,
} from "./use-video-tracking-handlers";
import { VideoDescription } from "./video-description";

const PERCENT_COMPLETE_TO_CONSIDER_WATCHED = 0.85;

export interface VideoPlayerProps {
  /**
   * ID of the video to play
   */
  videoId: string | undefined;

  /**
   * The video URL. This should ideally be a `.mp4` file
   */
  url: string | undefined;

  /**
   * If provided, displays below the video to indicate how many points
   * the user will receive for watching the video
   */
  pointsForVideo: number | undefined;

  /**
   * The title displayed above the video. This should be
   * `Video.title`
   */
  videoTitle: string | undefined;

  /**
   * Indicates if the video has been watched already,
   * for tracking purposes.
   */
  alreadyWatched?: boolean;

  /**
   * When `true`, renders this video as an inline thumbnail
   * which auto-plays the video (silently).
   */
  isPlayingFromThumbnail?: boolean;

  /**
   * If `isPlayingFromThumbnail` is `true`:
   *
   * `true` indicates that the video is currently playing as a thumbnail.
   * `false` indicates that the video is actually playing now
   */
  isThumbnail?: boolean;

  /**
   * Invoked when the user has finished watching enough
   * of the video to consider it 'watched'.
   *
   * This is useful for triggering any logic based on
   * the user having watched the video.
   *
   * If you want to do something after the video has finished
   * playing completely, use `onEnded`.
   */
  onWatched?(params: { videoWatchId: string }): void;

  /**
   * Invoked after the video has completely finished playing.
   */
  onEnded(params: { videoWatchId: string }): void;

  trackingHandlers?: VideoTrackingHandlers;
}

/**
 * Renders a full-screen video player.
 *
 * When `isPlayingFromThumbnail` is set, renders in-line on muted as a thumbnail,
 * and can rendered full-screen with audio using `isThumbnail={false}`.
 *
 * The thumbnail is implemented with a `<video muted>`, and is programmatically
 * played upon coming into view. When `isThumbnail` changes to `false`, the video
 * is programmatically restarted, and `muted` changes to `false` on the video.
 */
export function VideoPlayer({
  videoId,
  url,
  pointsForVideo,
  videoTitle,
  alreadyWatched,
  isThumbnail,
  isPlayingFromThumbnail,
  onWatched,
  onEnded,
  trackingHandlers: propTrackingHandlers,
}: VideoPlayerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const blurredVideoRef = useRef<HTMLVideoElement>(null);
  const onScreen = useOnScreen(ref, { threshold: 1 });
  const [videoOrientation, setVideoOrientation] = useState<
    "portrait" | "landscape" | undefined
  >();
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoDurationSeconds, setVideoDurationSeconds] = useState<number>();
  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);

  const { formatMessage } = useFormatters();

  const [mountedAt] = useState(() => new Date());
  const [canPlayThroughAt, setCanPlayThroughAt] = useState<Date>();

  const trackingHandlers = useVideoTrackingHandlers({
    videoId,
    alreadyWatched: Boolean(alreadyWatched),
    playingFromCard: isPlayingFromThumbnail,
  });

  const [recordVideoWatched] = useRecordVideoWatchedMutation();

  const createVideoWatchedPromise = async (): Promise<{
    videoWatchId: string | undefined;
  }> => {
    // record that the video was watched,
    // to make sure user gets the reward
    // in-case they navigate away early

    let videoWatchId: string | undefined = undefined;

    if (videoId) {
      try {
        const result = await recordVideoWatched({
          variables: {
            videoId,
          },
        });

        videoWatchId = result.data?.recordVideoWatchByMe.videoWatchId;

        if (videoWatchId) {
          onWatched?.({ videoWatchId });
        }
      } catch (error) {
        reportError(error);
      }
    } else {
      reportError(new Error("No video ID to record watch for"));
    }

    return { videoWatchId };
  };

  const handleVideoWatchedPromise = useRef<
    Promise<{ videoWatchId: string | undefined }> | undefined
  >(undefined);
  const videoWatchedInvoked = useRef(false);
  const handleVideoWatched = async () => {
    // ensure that this callback can only be called once.
    // we've also run into issues where the mutation is called thousands of times in a minute,
    // which puts a huge strain on the server and causes the API to become less responsive.
    // we haven't reproduced, but this is a precaution to avoid it from
    // happening again.
    if (videoWatchedInvoked.current) {
      return; // this function was already invoked; let's not continue
    }
    videoWatchedInvoked.current = true;

    // assign video watched promise to a ref,
    // so we can await it in the video ended handler
    // (in-case the video can't be marked as watched quick enough)
    handleVideoWatchedPromise.current = createVideoWatchedPromise();
  };

  const videoEndedInvoked = useRef(false);
  const handleVideoEnded = async () => {
    // ensure that this callback can only be called once.
    // we've also run into issues where the mutation is called thousands of times in a minute,
    // which puts a huge strain on the server and causes the API to become less responsive.
    // we haven't reproduced, but this is a precaution to avoid it from
    // happening again.
    if (videoEndedInvoked.current) {
      return; // this function was already invoked; let's not continue
    }
    videoEndedInvoked.current = true;

    // wait for the 'video watched' callback to complete
    // before running any potential further processing
    if (!handleVideoWatchedPromise.current) {
      handleVideoWatchedPromise.current = createVideoWatchedPromise();
    }
    const { videoWatchId } = await handleVideoWatchedPromise.current;

    // if user is watching video full-screen,
    // we should close it on video end so they can see a 'points earned' modal
    closeFullscreenIfOpen();

    // only handle video ended when user is actually watching the video
    // (and it's not a thumbnail)
    if (!isThumbnail) {
      const trackingParams = {
        videoDurationSeconds: videoDurationSeconds ?? 0,
        videoWatchId,
      };
      trackingHandlers.onEnd(trackingParams);
      propTrackingHandlers?.onEnd(trackingParams);

      if (videoWatchId) {
        onEnded({ videoWatchId });
      }
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();

      if (videoRef.current.currentTime === 0) {
        // ensure video ended handler can be invoked again,
        // in-case user is re-starting video
        videoWatchedInvoked.current = false;
        videoEndedInvoked.current = false;
        handleVideoWatchedPromise.current = undefined;
      }
    }
    if (blurredVideoRef.current) {
      blurredVideoRef.current.play();
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    if (blurredVideoRef.current) {
      blurredVideoRef.current.pause();
    }
  };

  // the video should be restarted:
  // - when it switches from thumbnail to fullscreen without mute (due to user clicking), OR
  // - when it's a thumbnail, and scrolls into view
  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
    if (blurredVideoRef.current) {
      blurredVideoRef.current.currentTime = 0;
    }
  };

  const wasPreviouslyThumbnail = usePrevious(isThumbnail);

  useEffect(() => {
    // restart & auto-play video once it switches from thumbnail
    // to full-screen
    if (wasPreviouslyThumbnail && !isThumbnail && isPlayingFromThumbnail) {
      restartVideo();
      playVideo();
    }
  }, [wasPreviouslyThumbnail, isThumbnail, isPlayingFromThumbnail]);

  useEffect(() => {
    // auto-play thumbnail once it comes into view
    if (onScreen && isThumbnail) {
      restartVideo();
      playVideo();
    }
  }, [onScreen, isThumbnail]);

  const DEFAULT_VIDEO_PROPS: React.VideoHTMLAttributes<HTMLVideoElement> = {
    playsInline: true,
    onTimeUpdate: (e: React.SyntheticEvent<HTMLVideoElement>) => {
      // loop the first 10 seconds of the video if it's just showing for the thumbnail
      if (e.currentTarget.currentTime > 10 && isThumbnail) {
        e.currentTarget.currentTime = 0;
      }

      // update our tracked state of the current time
      if (videoRef.current && !videoRef.current.seeking) {
        setCurrentTimeSeconds(videoRef.current.currentTime);
      }

      // if the user has watched enough of the video,
      // make an API call to consider the video 'watched'.
      // this is in-place to handle the case where a user ends
      // is in the final seconds, and then navigates away
      if (!isThumbnail && videoRef.current) {
        const percentComplete =
          videoRef.current.currentTime / videoRef.current.duration;
        if (percentComplete >= PERCENT_COMPLETE_TO_CONSIDER_WATCHED) {
          // note - handleVideoWatched handles being called many times in succession,
          // so it's ok that this is called many times as `onTimeUpdate` is invoked
          handleVideoWatched();
        }
      }
    },
    src: url,
  };

  const isReady = Boolean(videoOrientation);
  const getDurationAndPercentageCompleted = () => {
    const percentageCompleted =
      currentTimeSeconds / (videoDurationSeconds ?? Infinity);

    return {
      videoDurationSeconds: videoDurationSeconds!,
      percentageCompleted,
    };
  };

  return (
    <div
      css={(appTheme: AppTheme) => css`
        ${!isThumbnail &&
        css`
          ${isPlayingFromThumbnail &&
          css`
            position: fixed;
            top: 0;
            left: 0;
          `}
          width: 100%;
          height: 100%;
          background-color: #2c393f;
          z-index: 1000;
          display: flex;
          flex-direction: column;
          ${appTheme.breakpoints.up("lg")} {
            padding-top: ${COMPUTER_NAVIGATION_BAR_HEIGHT}px;
          }
        `}
      `}
    >
      {!isThumbnail && isPlayingFromThumbnail && (
        <SubPageHeader
          pageName={formatMessage({
            description: "Video offer page > page title",
            defaultMessage: "Video Reward",
          })}
          fixedBackNavigation
          onBackClick={() => {
            playVideo();
          }}
          analyticsPageName="Video"
          backTo="rewards"
        />
      )}
      <div
        css={(appTheme: AppTheme) => css`
          height: calc(100% - ${BACK_NAVIGATION_BAR_HEIGHT}px);
          ${appTheme.breakpoints.up("lg")} {
            height: calc(100% - ${COMPUTER_NAVIGATION_BAR_HEIGHT}px);
          }
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          ref={ref}
          css={(appTheme: AppTheme) => css`
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            ${isThumbnail
              ? css`
                  border-radius: 10px;
                  width: 100%;
                  aspect-ratio: 16 / 9;
                  background-color: ${appTheme.palette.grey[400]};
                `
              : css`
                  background-color: black;
                `}
            z-index: 0;
          `}
        >
          {!isReady && (
            <Skeleton
              css={css`
                transform: none;
                width: 100%;
                aspect-ratio: 16 / 9;
                border-radius: 0px;
                ${!isThumbnail &&
                css`
                  background-color: #2c393f;
                `}
              `}
              animated
              width="100%"
              height="100%"
            />
          )}
          {url && (
            <video
              {...DEFAULT_VIDEO_PROPS}
              data-testid="video-player"
              ref={videoRef}
              muted={isThumbnail}
              onCanPlayThrough={() => {
                const canPlayThroughAt = new Date();
                setCanPlayThroughAt(canPlayThroughAt);
                if (!isThumbnail) {
                  const trackingParams = {
                    loadTimeMilliseconds:
                      (canPlayThroughAt.getTime() - mountedAt.getTime()) / 1000,
                  };
                  trackingHandlers.onLoadEnoughToPlayThrough?.(trackingParams);
                  propTrackingHandlers?.onLoadEnoughToPlayThrough?.(
                    trackingParams
                  );
                }
              }}
              onWaiting={() => {
                if (!isThumbnail) {
                  const trackingParams = getDurationAndPercentageCompleted();
                  trackingHandlers.onLag(trackingParams);
                  propTrackingHandlers?.onLag(trackingParams);
                }
              }}
              onClick={() => {
                if (isThumbnail) {
                  const trackingParams = {
                    loadedEnoughToPlayThrough: Boolean(canPlayThroughAt),
                    ...getDurationAndPercentageCompleted(),
                  };
                  trackingHandlers.onPlay(trackingParams);
                  propTrackingHandlers?.onPlay(trackingParams);
                  return;
                }
                if (isPlaying) {
                  pauseVideo();
                  const trackingParams = getDurationAndPercentageCompleted();
                  trackingHandlers.onPause(trackingParams);
                  propTrackingHandlers?.onPause(trackingParams);
                } else {
                  const trackingParams = {
                    loadedEnoughToPlayThrough: Boolean(canPlayThroughAt),
                    ...getDurationAndPercentageCompleted(),
                  };
                  trackingHandlers.onPlay(trackingParams);
                  propTrackingHandlers?.onPlay(trackingParams);
                  playVideo();
                }
              }}
              onEnded={handleVideoEnded}
              disablePictureInPicture
              onSeeking={() => {
                // disable seeking forward in full screen
                // https://januszhou.github.io/2017/03/21/Prevent-HTML5-Video-Seeking/
                const delta =
                  videoRef.current!.currentTime - currentTimeSeconds;
                if (delta > 0.01) {
                  videoRef.current!.currentTime = currentTimeSeconds;
                }
              }}
              css={css`
                display: ${isReady ? "flex" : "none"};
                height: 100%;
                object-fit: ${isThumbnail ? "cover" : "contain"};
                ${videoOrientation === "landscape" &&
                css`
                  width: 100%;
                `}
              `}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onLoadedMetadata={() => {
                setVideoOrientation(
                  videoRef.current?.videoHeight! > videoRef.current?.videoWidth!
                    ? "portrait"
                    : "landscape"
                );
                if (videoRef.current?.duration) {
                  const videoDuration = videoRef.current.duration;
                  if (!isThumbnail) {
                    const trackingParams = {
                      videoDurationSeconds: videoDuration,
                      metadataLoadTimeMilliseconds:
                        (new Date().valueOf() - mountedAt.valueOf()) / 1000,
                    };
                    trackingHandlers.onLoadMetadata(trackingParams);
                    propTrackingHandlers?.onLoadMetadata(trackingParams);
                  }
                  setVideoDurationSeconds(videoDuration);
                }
              }}
            />
          )}
          <video
            {...DEFAULT_VIDEO_PROPS}
            ref={blurredVideoRef}
            muted
            css={css`
              display: ${isReady ? "flex" : "none"};
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              ${isThumbnail
                ? css`
                    transform: translateY(-50%);
                    width: 100%;
                  `
                : css`
                    height: 110%;
                    width: 110%;
                    object-fit: cover;
                    transform: translateX(-5%) translateY(-5%);
                  `}
              filter: blur(20px);
            `}
          />
          {(isThumbnail || !isPlaying) && isReady && <PlayButton />}
          {!isThumbnail && isReady && (
            <>
              <FullscreenButton
                onOpenFullscreen={() => {
                  trackingHandlers.onOpenFullScreen();
                  propTrackingHandlers?.onOpenFullScreen();
                }}
                videoRef={videoRef}
              />
              <ProgressBar
                currentTimeSeconds={currentTimeSeconds}
                isPlaying={isPlaying}
                videoDurationSeconds={videoDurationSeconds}
              />
            </>
          )}
        </div>
        {!isThumbnail && (
          <VideoDescription
            pointsForVideo={pointsForVideo}
            videoTitle={videoTitle}
            alreadyWatched={alreadyWatched}
          />
        )}
      </div>
    </div>
  );
}
