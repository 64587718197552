/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { BackNavigation } from "@rewards-web/shared/components/back-navigation";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useGoalActivation } from "../../../shared/goal/goal-card/lib";
import completeUrl from "../../../shared/goal/graphics/complete.png";
import { useFormattedGoalTitle } from "../../../shared/goal/use-formatted-goal-title";
import { PageContentContainer } from "../../../shared/page-content-container";
import { useGoalActivationPageDataQuery } from "./goal-activation-page-data.generated";

export function GoalActivationPage() {
  const { goal_id: goalId } = useParams<"goal_id">();

  const theme = useTheme();
  const track = useTrack();
  const navigate = useNavigate();
  const { formatMessage } = useFormatters();
  const snackbar = useSnackbar();
  const { activateGoalAndTrack, activationLoading } = useGoalActivation();

  const { data, error } = useGoalActivationPageDataQuery({
    onError: reportError,
    variables: {
      goalId: goalId!,
    },
  });

  const goalData = data?.getGoalById;
  const goalTitle = useFormattedGoalTitle(goalData ?? null);

  const { handleSubmit, control: formControl } = useForm<{
    availability: string;
  }>();

  const onSubmit = async (data: { availability: string }) => {
    if (!goalData) return;
    await activateGoalAndTrack(goalData, data.availability);
    snackbar.show({
      message: formatMessage({
        description: "Goal activation page > success message",
        defaultMessage: "Your availability has been submitted",
      }),
      severity: "success",
    });
    navigate(-1);
  };

  if (error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
        padding-top: ${theme.spacing(4)};
      `}
    >
      <PageContentContainer
        css={css`
          width: 100%;
          padding-top: 0;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            padding-top: ${theme.spacing(3)};
            position: relative;
            z-index: 1;
          `}
        >
          <BackNavigation
            height={64}
            maxContentWidth={theme.maxContentWidth}
            title={goalTitle ?? ""}
            centerTitle
            onBackClicked={() => {
              track("Goal activation page back button pressed", { goalId });
              navigate(-1);
            }}
            color="white"
          />
        </div>

        <img
          css={(theme: AppTheme) => css`
            display: block;
            object-fit: contain;
            margin: ${theme.spacing(2)} auto;
            max-height: 75px;
            max-width: 75px;
          `}
          src={completeUrl}
          alt="check mark"
        />

        <Typography
          css={css`
            text-align: center;
            margin: ${theme.spacing(1)};
          `}
          variant="h2"
          color="text.primary"
        >
          {formatMessage({
            description: "Goal activation page > page heading",
            defaultMessage: "I'm interested",
          })}
        </Typography>

        <Typography
          css={css`
            text-align: center;
          `}
          variant="body"
          color="text.primary"
        >
          {formatMessage({
            description: "Goal activation page > description",
            defaultMessage:
              "Please provide your available hours and dates (e.g., 9–5 pm Monday and Tuesday). Your coordinator will assign hours accordingly.",
          })}
        </Typography>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(3, 2)};
          `}
        >
          <Controller
            control={formControl}
            rules={{
              required: formatMessage({
                description: "Goal activation page > availability required",
                defaultMessage: "Please enter your availability",
              }),
              maxLength: 100,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={formatMessage({
                  description:
                    "Goal activation page > availability field label",
                  defaultMessage: "My availability",
                })}
                error={fieldState.error}
                inputProps={{ maxLength: 100 }}
                helperText={
                  fieldState.error?.message ??
                  formatMessage(
                    {
                      description:
                        "Goal activation page > availability field helper text",
                      defaultMessage:
                        "Characters remaining: {characters_remaining}",
                    },
                    {
                      characters_remaining: 100 - (field.value?.length || 0),
                    }
                  )
                }
                css={(theme: AppTheme) => css`
                  margin-bottom: 0;
                `}
              />
            )}
            name="availability"
          />

          <Button
            type="submit"
            label={formatMessage({
              description: "Goal activation page > submit button label",
              defaultMessage: "Submit",
            })}
            color="secondary"
            size="large"
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
            loading={activationLoading}
          />
        </Form>
      </PageContentContainer>
    </div>
  );
}
