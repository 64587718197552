/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EarnMorePointsButton } from "../earn-more-points-button";
import { useRedemptionPageData } from "../hooks";
import { PaycheckAndHSASelectorItem } from "./paycheck-hsa-selector-item";

interface PaycheckHSARedemptionInfoProps {
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  redemptionMethods: RedemptionMethod[];
  phoneNumber: string | undefined | null;
  email: string | undefined | null;
}

export function PaycheckHSARedemptionInfo({
  minimumPointsToRedeem,
  pointsPerDollar,
  redemptionMethods,
  phoneNumber,
  email,
}: PaycheckHSARedemptionInfoProps) {
  const track = useTrack();
  useEffect(() => {
    track("Viewed payroll hsa redemption selector", {
      methods: redemptionMethods,
    });
  }, [track, redemptionMethods]);

  if (
    !redemptionMethods.some((method) =>
      [RedemptionMethod.Hsa, RedemptionMethod.Payroll].includes(method)
    )
  ) {
    throw new Error(
      "Cannot render PaycheckHSARedemptionInfo without HSA or Payroll redemption methods"
    );
  }

  const { formatMessage } = useFormatters();

  const redemptionPageData = useRedemptionPageData();

  if (redemptionPageData.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description:
            "Points summary page > redeem page > points balance load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!redemptionPageData.data) {
    return <PageLoadingState />;
  }

  const { pointsAvailableToRedeem, isLocked } = redemptionPageData.data;

  const title = (() => {
    if (isLocked) {
      return formatMessage({
        defaultMessage: "🔒 Discover rewards",
        description: "Points summary page > redeem > locked header",
      });
    }

    return formatMessage({
      defaultMessage: "🤩 Redeem rewards now!",
      description: "Points summary page > redeem > unlocked header",
    });
  })();

  const subTitle = (() => {
    if (isLocked) {
      if (
        [RedemptionMethod.Hsa, RedemptionMethod.Payroll].every((method) =>
          redemptionMethods.includes(method)
        )
      ) {
        return formatMessage(
          {
            defaultMessage:
              "Earn {required_points, number} more points to redeem in your Health Spending Account or your paycheck.",
            description:
              "Points summary page > paycheck and hsa redeem > locked subtitle for both hsa and paycheck",
          },
          {
            required_points: minimumPointsToRedeem - pointsAvailableToRedeem,
          }
        );
      } else if (redemptionMethods.includes(RedemptionMethod.Payroll)) {
        return formatMessage(
          {
            defaultMessage:
              "Earn {required_points, number} more points to redeem in your paycheck.",
            description:
              "Points summary page > paycheck and hsa redeem > locked subtitle for paycheck",
          },
          {
            required_points: minimumPointsToRedeem - pointsAvailableToRedeem,
          }
        );
      }
      return formatMessage(
        {
          defaultMessage:
            "Earn {required_points, number} more points to redeem in your Health Spending Account.",
          description:
            "Points summary page > paycheck and hsa redeem > locked subtitle for hsa",
        },
        {
          required_points: minimumPointsToRedeem - pointsAvailableToRedeem,
        }
      );
    }
    if (
      [RedemptionMethod.Hsa, RedemptionMethod.Payroll].every((method) =>
        redemptionMethods.includes(method)
      )
    ) {
      return formatMessage({
        defaultMessage:
          "Redeem your rewards directly to your HSA or as an addition to your paycheck",
        description:
          "Points summary page > paycheck info > description for hsa and paycheck",
      });
    } else if (redemptionMethods.includes(RedemptionMethod.Payroll)) {
      return formatMessage({
        defaultMessage: "Redeem your rewards as an addition to your paycheck",
        description: "Points summary page > paycheck info > description",
      });
    } else {
      return formatMessage({
        defaultMessage: "Redeem your rewards directly to your HSA",
        description: "Points summary page > hsa info > description",
      });
    }
  })();

  return (
    <div>
      <Typography variant="h3">{title}</Typography>
      <Typography
        variant="body"
        css={(appTheme: AppTheme) => css`
          margin-bottom: ${appTheme.spacing(2)};
          color: ${appTheme.palette.grey[800]};
        `}
      >
        {subTitle}
      </Typography>
      {isLocked && <EarnMorePointsButton />}
      {redemptionMethods.includes(RedemptionMethod.Payroll) && (
        <PaycheckAndHSASelectorItem
          minimumPointsToRedeem={minimumPointsToRedeem}
          pointsPerDollar={pointsPerDollar}
          redemptionMethod={RedemptionMethod.Payroll}
          phoneNumber={phoneNumber}
          email={email}
          pointsAvailableToRedeem={pointsAvailableToRedeem}
          isLocked={isLocked}
        />
      )}

      {redemptionMethods.includes(RedemptionMethod.Hsa) && (
        <PaycheckAndHSASelectorItem
          minimumPointsToRedeem={minimumPointsToRedeem}
          pointsPerDollar={pointsPerDollar}
          phoneNumber={phoneNumber}
          redemptionMethod={RedemptionMethod.Hsa}
          email={email}
          pointsAvailableToRedeem={pointsAvailableToRedeem}
          isLocked={isLocked}
        />
      )}
    </div>
  );
}
