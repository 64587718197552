import { decodeJWT } from "@rewards-web/shared/lib/decode-jwt";

const ACCESS_TOKEN_STORAGE_KEY = "access_token";

let broadcastChannel: BroadcastChannel | undefined = undefined;

if ("BroadcastChannel" in window) {
  // only create the broadcast channel if it's supported by the browser
  broadcastChannel = new BroadcastChannel("access_token_channel");
}

export function getAccessToken() {
  return (
    localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ||
    sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
  );
}

export function saveAccessToken(accessToken: string) {
  const existingUserId = getExistingUserId();

  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);

  if (
    broadcastChannel &&
    getUserIdFromAccessToken(accessToken) !== existingUserId
  ) {
    // broadcast to other browser tabs that the token has been updated
    broadcastChannel.postMessage({ type: "LOGGED_IN_USER_CHANGED" });
  }
}

if (broadcastChannel) {
  // listen for to other browser tabs that the token has been updated
  broadcastChannel.addEventListener("message", (event) => {
    if (event.data.type === "LOGGED_IN_USER_CHANGED") {
      // redirect to home page because the current page may be able to load
      // (and may trigger an ACL check error).
      // this fixes a bug with impersonation where the user opens an impersonation link,
      // then the previous tab fails when it tries to load the page.
      window.location.href = "/";
    }
  });
}

/**
 * Save the access token to local storage, but only for the current session.
 * This is useful for temporary visit to the app, for ex: when impersonating users.
 */
export function saveAccessTokenForSession(accessToken: string) {
  sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
}

export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
}

export function getExistingUserId() {
  const accessToken = getAccessToken();

  if (accessToken) {
    return getUserIdFromAccessToken(accessToken);
  }

  return null;
}

export function getUserIdFromAccessToken(accessToken: string): string {
  const jwt = decodeJWT(accessToken);
  return jwt.user_id;
}

export function getUserIsImpersonatingFromAccessToken(
  accessToken: string
): boolean {
  const jwt = decodeJWT(accessToken);
  return jwt.impersonating === true;
}

export function getUserCompletedSmsCodeChallengeFromAccessToken(
  accessToken: string
): boolean {
  const jwt = decodeJWT(accessToken);
  return jwt.auth_method === "sms-code-challenge";
}
