/** @jsxImportSource @emotion/react */
import {
  BillableHoursGoalTracker,
  CompleteTrainingGoalDetails,
  EvvStreakGoalTracker,
  Goal,
  LastMinuteShiftGoalTracker,
  WeekendShiftGoalTracker,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { GoalIcon } from "../goal-icon";
import { useFormattedGoalTitle } from "../use-formatted-goal-title";
import { useAcknowledgeGoalAchievementMutation } from "./acknowledge-goal-achievement.generated";
import { BaseGoalAchievedModal } from "./base-goal-achieved-modal";

export interface GoalAchievedModalProps {
  open: boolean;
  onOpened(): void;

  /**
   * Invoked after the modal has been dismissed
   */
  onDismissed?(): void;

  /**
   * Invoked after the API call to acknowledge goal completion
   * has gone through.
   */
  onAcknowledgementCompleted?(): void;

  goal: Pick<
    Goal,
    | "id"
    | "type"
    | "numPoints"
    | "numCaribouSponsoredDrawTickets"
    | "achievedAt"
  > & {
    goalTracker:
      | { __typename: "JobShareGoalTracker" }
      | { __typename: "SurveyGoalTracker" }
      | ({ __typename: "EVVStreakGoalTracker" } & Pick<
          EvvStreakGoalTracker,
          "id" | "targetStreak"
        >)
      | { __typename: "WatchVideoGoalTracker" }
      | { __typename: "HHAXLoginGoalTracker" }
      | ({
          __typename: "LastMinuteShiftGoalTracker";
        } & Pick<LastMinuteShiftGoalTracker, "trackedVisit">)
      | ({ __typename: "WeekendShiftGoalTracker" } & Pick<
          WeekendShiftGoalTracker,
          "trackedVisit"
        >)
      | { __typename: "CompleteTrainingGoalTracker" }
      | { __typename: "BillableHoursGoalTracker" }
      | ({ __typename: "BillableHoursGoalTracker" } & Pick<
          BillableHoursGoalTracker,
          | "targetBillableHours"
          | "trackedBillableHours"
          | "requiresEVVCompliance"
        >);
  } & {
    goalDetails?:
      | { __typename?: "SurveyGoalDetails" }
      | { __typename?: "WatchVideoGoalDetails" }
      | ({ __typename?: "CompleteTrainingGoalDetails" } & Pick<
          CompleteTrainingGoalDetails,
          "trainingProgramName"
        >)
      | null;
  };

  analyticsData?: { [key: string]: any };
}

export function GoalAchievedModal({
  open,
  goal,
  onDismissed,
  onAcknowledgementCompleted,
  onOpened,
  analyticsData,
}: GoalAchievedModalProps) {
  const [
    acknowledgeGoalAchievementMutation,
  ] = useAcknowledgeGoalAchievementMutation();

  const goalTitle = useFormattedGoalTitle(goal);

  return (
    <BaseGoalAchievedModal
      open={open}
      id={goal.id}
      pointsEarned={goal.numPoints ?? 0}
      caribouSponsoredDrawTickets={goal.numCaribouSponsoredDrawTickets ?? 0}
      icon={<GoalIcon goalType={goal.type} />}
      goalAchievedDate={goal.achievedAt}
      goalDescription={goalTitle}
      analyticsData={{
        ...(analyticsData ?? {}),
        goalId: goal.id,
        goalType: goal.type,
        numPoints: goal.numPoints,
        numCaribouSponsoredTickets: goal.numCaribouSponsoredDrawTickets,
        achievedAt: goal.achievedAt,
      }}
      onClose={async () => {
        onDismissed?.();
        try {
          await acknowledgeGoalAchievementMutation({
            variables: { goalId: goal.id },
          });
        } catch (error) {
          reportError(error);
        }
        onAcknowledgementCompleted?.();
      }}
      onOpened={onOpened}
    />
  );
}
